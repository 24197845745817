.btn {
  cursor: pointer; }

.btn-red-opacity {
  background: rgba(152, 9, 19, 0.8);
  color: #ffffff !important;
  padding: 12px 20px;
  text-transform: uppercase;
  position: relative;
  font-size: 0.9em;
  transition: all 0.3s;
  display: inline-block;
  min-width: 280px;
  width: 100vw;
  max-width: 500px!important;
  text-align: left;
  @include font-medium;


  .round {
    border: 2px solid #fff;
    width: 28px;
    height: 28px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 7px;
    svg {
      margin-top: 2px; } }


  &:hover {
    color: #ffffff!important;
    background: rgba(152,9,19,1);
    border-color: #d39e00;
    transition: all 0.3s;
    text-decoration: none;
    @include font-bold; } }

.subcategory-link {
  z-index: 2;
  position: absolute;
  display: inline-block;
  .btn-red-opacity {
    @include font-bold;
    background: rgba(152, 9, 19, 1);

    & + .btn-red-opacity {
      background: #eee;
      color: #111111!important;
      display: none;
      .round {
        display: none; }
      &:hover {
        background: #ddd; } } }
  &:hover {
    .btn-red-opacity {
      & + .btn-red-opacity {

        display: block; } } } }





.btn-yellow {
  background: #ffe400;
  color: #111111;
  padding: 12px 20px;
  text-transform: uppercase;
  @include font-bold;

  font-size: 0.9em;

  transition: all 0.3s;
  &:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    transition: all 0.3s;
    text-decoration: none; } }
.btn-medium {
  padding: 7px 20px;
  font-size: 0.8em; }

.round {
  border: 2px solid #111111;
  border-radius: 500px;
  margin-left: 10px;
  * {
    stroke-width: 2; } }
.right-center-headline {
  position: absolute;
  right: 40px;
  bottom: -25px; }

.category-link {
  bottom: -50px; }
@media screen and (max-width: 960px) {
  .category-link {
    left: -30px!important;
    top: 0;
    position: relative!important;
    .btn-red-opacity {
      width: calc(100vw - 43px) !important; } }

  .subcategory-link {
    .btn-red-opacity {
      width: calc(100vw - 43px) !important; } } }



