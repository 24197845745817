@media screen and (max-width: 960px) {
  .slider_description {
     background: rgba(0, 0, 0, 0.4);

     width: 100%;
     min-width: 100%;
     bottom: 0;
     left: 0;
     .uk-h2 {
       font-size: 16px;
       margin-bottom: 0;
       padding-bottom: 0px;
       height: 30px; }
     p {
       font-size: 13px; }
     .idx {

       top: -30px; } } }
