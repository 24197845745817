.catalog-left-menu {
  .active > ul {
    display: block; }
  .active > a {
    color: #c73737; }
  .op, .on {
    color: #000000;
    width: 30px;
    text-align: center;
    * {
      stroke-width: 2; } }


  ul {
    margin-left: 0;
    padding-left: 0;
    li {
      position: relative;
      list-style-type: none;
      display: list-item;
      text-align: -webkit-match-parent;
      border-bottom: 1px solid #ccc;


      a {
        display: flex;
        padding-left: 0;
        align-items: center;
        column-gap: 0.25em;
        text-decoration: none;
        font-weight: 900;
        color: #000;
        font-size: 15px;
        min-height: 40px;
        border-bottom: 1px solid #ccc;
        padding-top: 10px;
        padding-bottom: 10px;
        &:hover {
          color: #c73737; } }

      li {
         &:last-child {
          border-bottom: 0px solid #ccc; }
         ul {
          display: none; }

         a {
           padding-left: 0px;
           border-bottom: none;
           font-size: 13px; }

         li {
            border-bottom: none;
            a {
              padding-left: 40px;
              min-height: 30px;
              font-size: 13px;
              padding-top: 0;
              padding-bottom: 0; }
            &:last-child {
              a {
                padding-bottom: 20px; } } } } } } }



