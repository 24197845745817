@media screen and (max-width: 960px) {
  .logo {
    position: relative!important;
    top: 10px;
    img {
      width: 140px; } }

  .top-menu {
    background: #000;

    .uk-icon {
      height: 48px;
      svg {
        height: 48px;
        rect {
          height: 2px; } } } }

  .nav2 {
    .uk-search-icon {
      padding: 0 5px!important; }
    ul {
      li {
        a {
          padding: 0 5px!important; } } } } }
.uk-offcanvas-bar {
  overflow: hidden;
  padding-right: 0;
  padding-top: 0;
  background: #f3f3f3;
  color: #000;
  ul {
    width: 100%;
    background: #f3f3f3;
    .uk-icon {
      color: rgba(0,0,0,.3);
      * {
        stroke-width: 2; } }
    li {
      position: relative;

      a {
        color: #111;
        min-height: 40px;
        border-bottom: 1px solid #ccc;
        font-size: 13px;
        line-height: 1em;
        text-transform: unset;
        &:hover {
          color: #000000; } }

      ul {
        position: absolute;
        left:  calc(100% + 40px);
        top: 0; } } } }


.next-ul {
  top: 0;
  left: -80px;
  position: absolute;
  border-left: 1px solid #ccc;
  height: 40px;
  border-bottom: 1px solid #ccc;
  width: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0,0,0,.3);
  cursor: pointer; }

.prev-ul, .prev-ul-top {
  border-bottom: 1px solid #ccc;
  min-height: 40px;
  margin-left: -20px;
  cursor: pointer;
  color: rgba(0,0,0,.3);
  .uk-icon {
    position: absolute;
    left: 15px;
    top: 3px; } }




