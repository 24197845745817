.logo {
  top: -20px;
  z-index: 2; }

.nav2 {
  background: #fff;
  min-height: 50px;
  position: relative;
  ul {
    li {
      a {
        min-height: 70px; } } } }
