.slider_description {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  max-width: 400px;
  padding: 30px;
  color: #fff;
  bottom: 300px;
  right: 300px;
  p {
    color: white; }

  .idx {
    position: relative;
    small {
      position: absolute;
      top: 17px;
      left: -10px;
      font-style: italic;
      font-size: 20px; } }


  .uk-h2 {
    font-size: 24px;
    @include font-bold;
    text-transform: uppercase;
    max-width: 75%;
    min-height: 60px;
    color: #ffffff;
    margin-top: 0; }

  .idx {
    @include font-thin;
    font-size: 60px;
    position: absolute;
    top: 10px;
    right: 20px; } }

.slider-dot {
  padding-bottom: 130px;
  .dotitem {
    line-height: 1.1em;
    list-style-type: none;
    position: relative;
    width: 117px;
    padding: 14px 10px 20px;
    border-right: 1px solid rgba(255,255,255,.2);
    cursor: pointer;
    a {

      color: #ffffff;
      text-transform: uppercase;
      font-size: 0.8em;
      &:hover {
        text-decoration: none; } }
    span.idx {
      position: absolute;
      top: 0px;
      right: 4px;
      color: rgba(255,255,255,.7);
      font-size: 12px;
      font-weight: 100; } }
  .slider-green {
    background: rgba(26,99,0,.8);
    &:hover {
      background: rgba(26,99,0,1); } }
  .slider-red {
    background: rgba(152,9,19,.8);
    &:hover {
      background: rgba(152,9,19,1); } }
  .uk-active {
    background: $bg-one;
    a, span, .idx {
      color: #000!important; }
    a {
      @include font-bold; }

    &:hover {
      background: $bg-one;

      a {
        background: $bg-one;
        color: #000; } }

    .slider_read-more {
      span {
        display: block;
        * {
          stroke-width: 2; } } } }

  .slider_read-more {
    position: absolute;
    bottom: 5px;
    right: 0px;
    color: #111111;
    display: inline-block;
    span {
      display: none; } } }
.dot-nav-line {
  li {
    background: #ddd;
    height: 4px;
    margin-left: 4px;
    width: 35px;
    transition: all 0.5s;
    cursor: pointer;

    * {
      border: 0;
      background: transparent;
      &:hover {
        background: transparent; } } }

  .uk-active {
    background: #980913;
    transition: all 0.5s;
    * {
      background: transparent; } } }
.news-slider {
  strong {
    @include font-bold;
    font-size: 17px;
    color: #000; }
  .title {
    font-size: 16px;
    @include font-medium;
    line-height: 1em;
    margin: 10px 0; }
  p {
    margin: 0; }
  &::after {
    content: " ";
    position: absolute;
    background:  $bg-one;
    height: 20px;
    width: 270px;
    z-index: 4;
    bottom: -18px;
    right: 12px; }
  li {}

  .news-slider_description {
    background: $bg-one;
    background: #ffe400;
    position: absolute;
    bottom: 0px;
    right: 12px;
    padding: 20px 15px;
    width: 270px;
    z-index: 3; } }

