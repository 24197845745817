

.uk-search-input {
  background: #ffffff!important; }

.search-top-parent {
    position: absolute;
    left: unset;
    top: 13px;
    right: 20px;
    width: 300px;

    .uk-search-input {
      padding-right: 40px; } }


@media screen and (max-width: 960px) {
  .search-top-parent {
    position: absolute;
    width: calc(100vw - 200px);
    right: 0px;
    top: 13px;
    border-radius: 3px; } }




