.card {
  &:hover {
    background:  $bg-three;
    * {
      color:  white; }
    transition: all 0.3s; }
  cursor: pointer;
  .desc {}


  p {
    line-height: 1.3em;

    font-size: 0.9em; } }
.item-card {
  cursor: pointer;
  .round {
    top: 20px!important;
    position: absolute;
    right: 0px; }
  .uk-card-title {
    font-size: 18px!important;
    @include font-bold; }
  &:hover {
    img {
      opacity: 0.8;
      transition: all 0.2s; } } }

