.uk-accordion {
    li {
        border: 1px solid #e2e2e2;
        border-top: 0; } }
.uk-accordion-title {
    background: #eee;
    border: 1px solid #e2e2e2;
    padding: 15px;
    border-bottom: 0;
    color: #444;
    font-weight: bold;
    font-size: 16px;
    &:before {
      display: none; } }
.uk-accordion-content {
    padding: 10px;
    margin-top: -2px; }
