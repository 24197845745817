// Call mixin for add font to project
@include font('Roboto Regular', 'roboto-regular');

// Create mixin for quick fonts applying
@mixin font-regular {
  font-family: 'Roboto Regular', sans-serif;
  font-weight: 400; }
@mixin font-italic {
  font-family: 'Roboto Italic', sans-serif;
  font-style: italic; }

@mixin font-medium {
  font-family: 'Roboto Medium', sans-serif;
  font-weight: 500; }

@mixin font-light {
  font-family: 'Roboto Light', sans-serif;
  font-weight: 300; }

@mixin font-bold {
  font-family: 'Roboto Bold', sans-serif;
  font-weight: 600; }
@mixin font-thin {
  font-family: 'Roboto Thin', sans-serif;
  font-weight: 100; }
strong {
  color: #000000; }

body {

  @include font-regular; }

h1, .h1-main {
  @include font-bold;
  text-transform: uppercase;
  color: #111111;
  font-size: 35px; }

.h1-main {
  padding: 40px 0 20px; }
.h1-product {
  @include font-bold;
  text-transform: uppercase;
  color: #111111;
  font-size: 24px;
  padding: 40px 0 20px; }
h3 {
  @include font-bold;
  text-transform: uppercase;
  color: #111111; }
.color-black {
  color: black; }
.color-white {
  color: white; }
p {
  color: #111111; }
.red_bg_affter {
  &::after {
    background: $bg-three!important; } }
.red_bg_j {
  background: $bg-three!important; }
.red_bg {
  background: $bg-three!important;
  &:focus {
    color: white!important; }
  * {
   color: white!important;
   border-color: white!important; } }

.color-red {
  color: $bg-three; }
.bg-black {
  background: #111111; }
.pointer {
  cursor: pointer; }

.text {
  strong {
    @include font-bold; } }

.fitVids-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0; }

.fitVids-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  height: 100%; }

.greybg {
  background: #f6f6f6; }

a {
  color: #ae030f;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #7a030f; } }
.content-text {
  min-height: calc(100vh - 900px);
  h2 {
    font-size: 1.4rem;
    color: #000;
    @include font-bold; } }
.simplesearch-paging, .simplesearch-results {
  display: none; }

.fitVids-wrapper-p {
  max-width: 600px;
  text-align: center;
  margin: 0 auto; }
