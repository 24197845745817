#persons {
  .contact_fulname {
    margin: 0; }
  .contact_username {
   margin-top: 10px;
   display: inline-block; }
  .contact_photo {
    img {
      min-width: 130px; } }

  .btn {
    display: none; }

  h3 {
    text-transform: unset; } }
