.top-menu {
  background: #111111;
  .uk-navbar-dropdown {
    background: transparent;
    ul {
      li {
        a {
          color: #fff!important; } } } }
  .uk-grid {
    margin-top: 10px; }
  ul {
    li {
      a {
        color: #fff;
        min-height: 64px;
        transition: 0.2s all;
        @include font-medium;

        &:hover {
          color: #c73737!important;
          transition: 0.2s all; } } }

    .uk-active {
      a {
        color: #c73737;
        transition: 0.2s all; } }
    .uk-nav-header {
      color: #ffffff;
      @include font-bold;
      a {
        color: #ffffff;
        @include font-bold; } }
    .header-2 {
      & > a {

          text-transform: uppercase;
          @include font-medium; }


      .uk-nav {
        margin-top: 10px;
        a {
          line-height: 1em;
          min-height: 20px; } } } }

  .uk-navbar-dropdown-grid {
    & > :not(.uk-first-column)::before {
      border-left: 0px; }
    padding-bottom: 40px; } }

.uk-navbar-dropbar-slide {
    background: rgba(17, 17, 17, 0.89); }

.nav2 {
   min-height: 70px!important;
   a {
      min-height: 70px!important; }
   .nav2_top-desc {
      margin-left: 330px;
      color: #111;
      display: flex;

      align-items: center;
      min-height: 70px;
      flex-direction: row;
      align-content: stretch;
      justify-content: flex-start; } }



